import { LinksFunction } from '@remix-run/node';
import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import * as React from 'react';
import type { EventSourceMap } from 'remix-utils/sse/react';
import { EventSourceProvider } from 'remix-utils/sse/react';
import { useDehydratedState } from 'use-dehydrated-state';
import { env } from '~/.server/environment';
import createBuilderStore from '~/routes/app+/mazes+/models+/build+/builder-store';
import stylesheet from '~/tailwind.css?url';
import { version } from '../../package.json';

export async function loader() {
  return Response.json({
    ENV: {
      production: env.NODE_ENV === 'production',
      sentry_dsn: env.SENTRY_DSN,
      version: {
        build: process.env.BUILD_SHA || '4d522e48',
        version: `v${version}`,
      },
    },
  });
}

function App() {
  const map: EventSourceMap = new Map();

  const createdStore = createBuilderStore();
  const store = React.useRef(createdStore).current;

  const loaderData = useLoaderData<typeof loader>();

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000,
          },
        },
      }),
  );
  const dehydratedState = useDehydratedState();

  return (
    <html lang="en" className="scroll-smooth antialiased">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <EventSourceProvider value={map}>
          <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={dehydratedState}>
              <Outlet />
              <script
                dangerouslySetInnerHTML={{
                  __html: `window.ENV = ${JSON.stringify(loaderData.ENV)}`,
                }}
              />
            </HydrationBoundary>
          </QueryClientProvider>
        </EventSourceProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  let content;
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      content = (
        <>
          <span className="text-4xl">🧭 Are you lost?</span>
          <p className="text-base italic">
            <Link to={'/app'} className="underline">
              ... go home
            </Link>
          </p>
          {error.data.message ? (
            <pre className="text-smflex mt-6 w-full flex-wrap text-wrap">
              {error.data.message}
            </pre>
          ) : undefined}
        </>
      );
    } else if (error.status === 401) {
      content = (
        <>
          <span className="text-4xl">🔐 Up to no good, eh?</span>
          <p className="text-base italic">
            <Link to={'/app'} className="underline">
              ... go home
            </Link>
          </p>
          {'message' in error.data ? (
            <pre className="text-smflex mt-6 w-full flex-wrap text-wrap">
              {error.data.message}
            </pre>
          ) : undefined}
        </>
      );
    } else {
      content = (
        <>
          <span className="text-4xl">💣 Something bad happened</span>
          <p className="text-base italic">
            <Link to={'/app'} className="underline">
              ... go home
            </Link>
          </p>
          {'message' in error.data ? (
            <pre className="text-smflex mt-6 w-full flex-wrap text-wrap">
              {error.data.message}
            </pre>
          ) : undefined}
        </>
      );
    }
  } else if (error instanceof Error) {
    console.error(error.stack);
    content = (
      <>
        ⚠️ You probably broke something
        <p className="text-base italic">
          <Link to={'/app'}>... go home</Link>
        </p>
        <pre className="text-smflex mt-6 w-full flex-wrap text-wrap">
          {error.name}: {error.message}
        </pre>
      </>
    );
  } else {
    return <h1>Unknown Error</h1>;
  }

  return (
    <html lang="en">
      <head>
        <title>Divert - Lost?</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex h-screen select-none flex-col bg-zinc-900">
          <div className="flex justify-center">
            <img
              src="/logo.png"
              alt="company logo"
              className="mt-16 w-[350px]"
              draggable="false"
            />
          </div>
          <div className="flex h-full flex-1 items-center justify-center text-center">
            <div className="w-[350px] text-zinc-300">{content}</div>
          </div>
        </div>

        <Scripts />
      </body>
    </html>
  );
}

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  {
    rel: 'icon',
    href: '/favicon.png',
    type: 'image/png',
  },
  {
    rel: 'preload',
    href: '/logo.png',
    as: 'image',
  },
];
